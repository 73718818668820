<template>
  <!-- funciona -->
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="va-row">
        <div class="flex container-ddh-menu xs12 with-bottom va-row">
          <div class="flex xs7 no-padding header-back-button">
            <router-link :to="{ name: 'adoptions-manager' }" class="btn-back">
              <!-- <span class="fa fa-arrow-left"></span> -->
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.74323 1L9.03341 2.33967L4.49325 7.05271H21.0103V8.94711H4.49325L9.03341 13.6603L7.74288 15L0.999832 8L7.74323 1Z"
                  fill="#AFA2BA"
                  stroke="#AFA2BA"
                  stroke-width="1.44"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>

            <h1>Nova Turma</h1>

            <div v-if="schoolLogo == null">
              <input
                type="file"
                id="files"
                class="hide"
                ref="files"
                @change="uploadImage"
              />
            </div>
          </div>
          <div class="flex xs5 no-padding text-right btn-group-custom">
            <div class="va-row header-button">
              <!-- <div class="flex no-padding edit-grade">
                <button
                  v-on:click="goToStudentsList()"
                  class="btn btn-primary cancel"
                >
                  VER ALUNOS
                </button>
              </div> -->

              <div class="flex no-padding add-grade">
                <button v-on:click="showTeacherModal()" class="btn btn-change">
                  ADICIONAR PROFESSOR
                </button>
              </div>

              <vuestic-modal
                :show.sync="showTeacherModal"
                v-bind:large="false"
                ref="teacherModal"
                :okText="'modal.confirm' | translate"
                :cancelText="'modal.cancel' | translate"
              >
                <div slot="title">Adicionar professor</div>

                <div class="flex flex-column">
                  <div class="flex xs12 no-padding-right no-padding-bottom">
                    <input-normal
                      id="teacherName"
                      type="text"
                      label="Digite o nome do professor"
                      name="teacherName"
                      v-validate="'required'"
                      :value="teacherName"
                      :disabled="false"
                      :error="
                        errors.first('teacherName')
                          ? 'Preencher o nome do professor'
                          : ''
                      "
                      @input="teacherName = $event.target.value"
                    />
                  </div>

                  <div class="flex xs12 no-padding-right no-padding-bottom">
                    <input-normal
                      id="teacherEmail"
                      type="text"
                      label="Digite o e-mail do professor"
                      name="teacherEmail"
                      v-validate="'required'"
                      :value="teacherEmail"
                      :disabled="false"
                      :error="
                        errors.first('teacherEmail')
                          ? 'Preencher o email do professor'
                          : ''
                      "
                      @input="teacherEmail = $event.target.value"
                    />
                  </div>
                </div>

                <div class="flex md12 text-right">
                  <button class="btn btn-change right" @click="submitTeacher()">
                    Confirmar
                  </button>

                  <button
                    class="btn btn-primary right cancel"
                    v-on:click="closeTeacherModal()"
                  >
                    Cancelar
                  </button>
                </div>
              </vuestic-modal>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>

    <div class="container-dhh va-row">
      <div class="flex md12 xs12 no-padding">
        <h4></h4>
      </div>

      <div class="flex md12 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding fiscal-notes">
              <div class="body-spacing">
                <div class="va-row">
                  <div class="sets-list table-list-simple team">
                    <div class="va-row header no-padding">
                      <div class="flex item">ADICIONAR TURMA</div>
                    </div>
                    <div>
                      <div class="va-row">
                        <div class="flex xs12 md12 no-padding">
                          <div class="va-row">
                            <div class="flex xs2 no-padding-bottom">
                              <select-normal
                                :items="grades_types"
                                name="typeName"
                                label="Selecione um ano"
                                v-validate="'required'"
                                :disabled="false"
                                :data-vv-as="'ano'"
                                :value="selectedGrade"
                                :error="errors.first('typeName')"
                                @change="onChangeGradeType($event)"
                              />
                            </div>

                            <div
                              class="flex xs2 no-padding-right no-padding-bottom"
                            >
                              <input-normal
                                id="input-complement"
                                type="text"
                                label="Complemento"
                                name="name"
                                v-validate="'required'"
                                :value="complemento"
                                :disabled="false"
                                :error="errors.first('name') ? 'Preencher' : ''"
                                :data-vv-as="'complemento'"
                                @input="complemento = $event.target.value"
                              />
                            </div>

                            <div
                              class="flex xs3 no-padding-right no-padding-bottom"
                            >
                              <select-normal
                                :items="products"
                                name="product"
                                label="Selecione o produto principal"
                                v-validate="'required'"
                                :required="true"
                                :disabled="false"
                                :data-vv-as="'produto'"
                                value=""
                                :error="errors.first('product')"
                                @change="onChangeProduct($event)"
                              />
                            </div>

                            <div class="error text-center">
                              {{ errors.first("error") }}
                            </div>

                            <div class="flex md3">
                              <b>PROFESSOR</b>
                              <!-- Nome do professor -->
                              <span
                                v-if="teacherNameSelected === ''"
                                class="created-data"
                              >
                                Sem professor atrelado
                              </span>
                              <span v-else class="created-data">
                                {{ teacherNameSelected }}
                              </span>

                              <!-- Email do professor -->
                              <span
                                v-if="teacherEmailSelected === ''"
                                class="created-data"
                              >
                                <!-- Não exibe nada quando o email é vazio -->
                              </span>
                              <span v-else class="created-data">
                                {{ teacherEmailSelected }}
                              </span>
                            </div>

                            <div class="flex md2 text-right">
                              <button
                                id="btn-save-class"
                                type="button"
                                class="btn btn-primary right"
                                @click="saveGrade()"
                              >
                                SALVAR
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                    <div
                      v-if="showNotification"
                      :class="[
                        isErrorNotification
                          ? 'notification-error'
                          : 'notification-success',
                        'notification',
                      ]"
                    >
                      {{ errorMessage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex md12 xs12 no-padding">
        <h4></h4>
      </div>
      <div class="flex md12 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding fiscal-notes">
              <div class="body-spacing">
                <div class="va-row">
                  <div
                    class="sets-list table-list-simple team"
                    v-if="listTeachers.length > 0"
                  >
                    <div class="va-row header no-padding">
                      <div class="flex item">PROFESSOR</div>

                      <div class="flex item teacher">EMAIL</div>

                      <!-- <div class="flex item">LISTA DE ALUNOS</div> -->
                      <div class="flex item actions">
                        AÇÃO
                        <span
                          content="É possível realizar a exclusão de uma turma até o pedido ser enviado para produção. Depois de enviado para a produção a função excluir fica inativa."
                          v-tippy="{
                            interactive: true,
                            arrow: true,
                          }"
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.0625 10.6062C4.67083 10.8687 5.31667 11 6 11C6.69167 11 7.34167 10.8687 7.95 10.6062C8.55833 10.3437 9.0875 9.98542 9.5375 9.53125C9.9875 9.07708 10.3437 8.54583 10.6062 7.9375C10.8687 7.32917 11 6.68333 11 6C11 5.30833 10.8687 4.65833 10.6062 4.05C10.3437 3.44167 9.9875 2.9125 9.5375 2.4625C9.0875 2.0125 8.55833 1.65625 7.95 1.39375C7.34167 1.13125 6.69583 1 6.0125 1C5.32083 1 4.67083 1.13125 4.0625 1.39375C3.45417 1.65625 2.92292 2.0125 2.46875 2.4625C2.01458 2.9125 1.65625 3.44167 1.39375 4.05C1.13125 4.65833 1 5.30417 1 5.9875C1 6.67917 1.13125 7.32917 1.39375 7.9375C1.65625 8.54583 2.01458 9.07708 2.46875 9.53125C2.92292 9.98542 3.45417 10.3437 4.0625 10.6062ZM6 9C5.68934 9 5.4375 8.74816 5.4375 8.4375V6.0625C5.4375 5.75184 5.68934 5.5 6 5.5C6.31066 5.5 6.5625 5.75184 6.5625 6.0625V8.4375C6.5625 8.74816 6.31066 9 6 9ZM6 4.5C6.21212 4.5 6.39015 4.43182 6.53409 4.29545C6.67803 4.15909 6.75 3.98485 6.75 3.77273C6.75 3.5606 6.67803 3.37879 6.53409 3.22727C6.39015 3.07576 6.21212 3 6 3C5.78788 3 5.60985 3.07576 5.46591 3.22727C5.32197 3.37879 5.25 3.5606 5.25 3.77273C5.25 3.98485 5.32197 4.15909 5.46591 4.29545C5.60985 4.43182 5.78788 4.5 6 4.5Z"
                              fill="#68596E"
                            />
                          </svg>
                        </span>
                      </div>
                      <!-- <div class="flex item">LIVROS CRIADOS</div> -->
                    </div>

                    <div
                      class="va-row lines"
                      v-for="item in listTeachers"
                      :key="item.id"
                    >
                      <div class="flex item">
                        {{ item.name }}
                      </div>
                      <div class="flex item teacher">
                        {{ item.email }}
                      </div>

                      <div class="flex item actions text-right">
                        <div>
                          <!-- Botão para editar -->
                          <button
                            class="btn btn-change"
                            v-on:click="showEditTeacherModal(item)"
                          >
                            EDITAR
                          </button>

                          <!-- Botão para desvincular -->
                          <button
                            class="btn btn-primary cancel add-grade"
                            v-if="item.linked"
                            @click="changeTeacher(item)"
                          >
                            DESVINCULAR
                          </button>

                          <!-- Botão para vincular -->
                          <button
                            class="btn btn-change add-grade"
                            v-else
                            @click="changeTeacher(item)"
                          >
                            VINCULAR À TURMA
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div v-if="list.length <= 0">
                    <p>Nenhuma turma foi adicionada ainda.</p>
                    <button
                      v-on:click="showGrade(emptyGrade, 'add')"
                      class="btn btn-change"
                    >
                      ADICIONAR TURMA
                    </button>
                  </div> -->
                </div>

                <vuestic-modal
                  :show.sync="showEditTeacherModalVariable"
                  v-bind:large="false"
                  ref="editTeacherModal"
                  :okText="'modal.confirm' | translate"
                  :cancelText="'modal.cancel' | translate"
                >
                  <div slot="title">Editar professor</div>

                  <div class="flex flex-column">
                    <div class="flex xs12 no-padding-right no-padding-bottom">
                      <input-normal
                        id="editTeacherName"
                        type="text"
                        label="Digite o nome do professor"
                        name="editTeacherName"
                        v-validate="'required'"
                        :value="teacherName"
                        :disabled="false"
                        :error="
                          errors.first('editTeacherName')
                            ? 'Preencher o nome do professor'
                            : ''
                        "
                        @input="teacherName = $event.target.value"
                      />
                    </div>

                    <div class="flex xs12 no-padding-right no-padding-bottom">
                      <input-normal
                        id="editTeacherEmail"
                        type="text"
                        label="Digite o e-mail do professor"
                        name="editTeacherEmail"
                        v-validate="'required'"
                        :value="teacherEmail"
                        :disabled="false"
                        :error="
                          errors.first('editTeacherEmail')
                            ? 'Preencher o email do professor'
                            : ''
                        "
                        @input="teacherEmail = $event.target.value"
                      />
                    </div>
                  </div>

                  <div class="flex md12 text-right">
                    <button class="btn btn-change right" @click="editTeacher()">
                      Confirmar
                    </button>

                    <button
                      class="btn btn-primary right cancel"
                      v-on:click="closeEditTeacherModal()"
                    >
                      Cancelar
                    </button>
                  </div>
                </vuestic-modal>

                <vuestic-modal
                  :show.sync="show"
                  v-bind:large="true"
                  ref="gradeModal"
                  :okText="'modal.confirm' | translate"
                  :cancelText="'modal.cancel' | translate"
                  :noButtons="true"
                >
                  <div slot="title" v-if="gradeModalType === 'view'">
                    Visualizar turma
                  </div>
                  <div slot="title" v-if="gradeModalType === 'edit'">
                    Editar turma
                  </div>

                  <div slot="title" v-if="gradeModalType === 'delete'">
                    Excluir turma
                  </div>
                  <div slot="title" v-if="gradeModalType === 'add'">
                    Adicionar turma
                  </div>

                  <div v-if="gradeSelected !== null">
                    <grade-edit-manager
                      :grade="gradeSelected"
                      :school="schoolSelected"
                      :typeSend="'post'"
                      v-if="gradeModalType === 'add'"
                    ></grade-edit-manager>
                    <grade-edit-manager
                      :grade="gradeSelected"
                      :school="schoolSelected"
                      :typeSend="'put'"
                      v-if="gradeModalType === 'edit'"
                    ></grade-edit-manager>

                    <grade-view-manager
                      :grade="gradeSelected"
                      v-if="gradeModalType === 'view'"
                    ></grade-view-manager>
                    <grade-delete-manager
                      :grade="gradeSelected"
                      v-if="gradeModalType === 'delete'"
                    ></grade-delete-manager>
                  </div>
                </vuestic-modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      <vuestic-modal
        :show.sync="show"
        v-bind:large="true"
        ref="optionModalModal"
        :okText="'modal.confirm' | translate"
        :cancelText="'modal.cancel' | translate"
        :noButtons="true"
        :cancelDisabled="true"
      >
        <div slot="title" v-if="typeModalconfirm === 'nf'">Nota fiscal</div>
        <div slot="title" v-if="typeModalconfirm === 'adoption_delete'">
          Excluir adoção
        </div>
        <div slot="title" v-if="typeModalconfirm === 'checkout'">
          Gerar pagamento
        </div>

        {{ confirmMessage }}

        <br /><br />

        <!-- {{ errors }} -->

        <div class="error-label text-center" v-if="errors.items[0]">
          <!-- {{ errors }} -->

          {{ errors.items[0].msg }}
        </div>

        <hr />
        <br />

        <div class="va-row text-right">
          <div class="flex md12 text-right no-padding">
            <button class="btn btn-primary cancel" @click="closeModal()">
              CANCELAR
            </button>

            <button
              class="btn right"
              v-if="typeModalconfirm === 'nf'"
              @click="sendNF(currentItemModal)"
            >
              CONFIRMAR
            </button>
            <button
              class="btn right"
              v-if="typeModalconfirm === 'adoption_delete'"
              @click="deleteAdoption(currentItemModal)"
            >
              EXCLUIR
            </button>
            <button
              class="btn right"
              v-if="typeModalconfirm === 'checkout'"
              @click="sendCheckout(currentItemModal)"
            >
              CONFIRMAR
            </button>
          </div>
        </div>
      </vuestic-modal>

      <vuestic-modal
        :show.sync="show"
        v-bind:large="true"
        ref="miniModal"
        :okText="'modal.confirm' | translate"
        :cancelText="'modal.cancel' | translate"
        :noButtons="true"
        :cancelDisabled="true"
      >
        <div slot="title">Sucesso</div>

        {{ customResponse }}

        <br /><br />
        <hr />
        <br />
        <div class="va-row">
          <div class="flex md12 text-right no-padding">
            <button class="btn btn-white right cancel" @click="closeModal()">
              OK, ENTENDI
            </button>
          </div>
        </div>
      </vuestic-modal>

      <vuestic-modal
        :show.sync="show"
        v-bind:large="true"
        ref="miniModalBookEdit"
        :okText="'modal.confirm' | translate"
        :cancelText="'modal.cancel' | translate"
        :noButtons="true"
        :cancelDisabled="true"
      >
        <div slot="title">Editar produtos</div>

        <books-edit
          v-if="products"
          :products="products"
          :productsExtra="productsExtra"
          :projectInfos="projectInfos"
          @closeAndSaveBooksEdit="closeAndSaveBooksEdit()"
          @closeEditBooks="closeEditBooks()"
        />

        <!-- {{ customResponse }} -->

        <!-- <br /><br />
				<hr />
				<br />
				<div class="va-row">
					<div class="flex md12 text-right no-padding">
						<button class="btn btn-white right cancel" @click="closeModal()">
							OK, ENTENDI
						</button>
					</div>
				</div> -->
      </vuestic-modal>

      <vuestic-modal
        :show.sync="url"
        :titleDisabled="false"
        v-bind:large="true"
        id="cropModal"
        ref="cropModal"
        class="crop-upload-body"
        :okText="'modal.confirm' | translate"
        :cancelText="'modal.cancel' | translate"
        :noButtons="true"
      >
        <div class="close"></div>

        <div v-if="!loadingCrop">
          <div class="va-row">
            <h1>Carregar logo</h1>
            <!-- <hr />
            <p>A foto será aplicada quadrada no livro.</p> -->
          </div>

          <Cropper
            v-if="imgLogoCrop"
            class="upload-example-cropper"
            :src="imgLogoCrop"
            ref="cropper"
            :stencil-component="Stencil"
            image-restriction="stencil"
            :default-size="{ width: 500, height: 500 }"
          />

          <canvas
            ref="resizeLogoCCanvas"
            style="width: 500px; height: 500px; display: none"
          ></canvas>

          <div class="va-row cta-crop">
            <a href="#" class="btn btn-change right" @click.prevent="crop"
              >ENVIAR</a
            >

            <a
              href="#"
              class="btn btn-primary right cancel"
              @click.prevent="closeModal"
              >CANCELAR</a
            >
          </div>
        </div>

        <div v-else class="text-center">
          <h1>Estamos carregando sua foto</h1>
          <hr />
          <p>Aguarde alguns instantes</p>

          <div id="loading"></div>
        </div>
      </vuestic-modal>
    </div>
  </div>
</template>

<script>
// import { required, minLength, between } from 'vuelidate/lib/validators'
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
//import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { Cropper } from "vue-advanced-cropper";
import FaceStencil from "./cropHelper/FaceStencil";
import "vue-advanced-cropper/dist/style.css";
import Stencil from "./cropHelper/Stencil";

// import VuesticTreeRoot from './VuesticTreeRoot.vue'
// import VuesticTreeNode from './VuesticTreeNode'
// import VuesticTreeCategory from './VuesticTreeCategory'

const axios = require("axios");

export default {
  name: "GradesManager",
  directives: { mask },
  // components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
  components: { Cropper },
  computed: {
    ...mapGetters({
      managerCredential: "managerCredential",
      // ...
    }),
  },

  data() {
    return {
      list: [],
      listTeachers: [],
      projectInfos: [],
      products: [],
      productsExtra: [],
      editing: false,
      emptySchool: {
        name: "",
        address: {
          district: "",
          address_number: "",
          state: "",
          city: "",
          address1: "",
          address2: "",
          zip_postal_code: "",
        },
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        type: 0,
        project_id: 1, //
      },

      emptyGrade: {
        role_id: "20",
        project_id: "1",
        name: "",
        grade_type_id: "",
        period_id: "",
        year: "",
        school_id: "",
        size: "",
        deadline: "",
        teacher: {
          email: "",
          name: "",
          phone: "",
          gender: "",
        },
      },

      schoolModalType: null,
      schoolSelected: null,

      gradeInfo: null,

      gradeModalType: null,
      gradeSelected: null,
      grades_types: [],
      selectedKey: null,

      files: "",
      show: false,

      name: null,
      complemento: "",

      showNameEmailModal: false,
      teacherName: "",
      teacherEmail: "",

      teacherNameSelected: "",
      teacherEmailSelected: "",
      teacherIdSelected: "",

      customResponse: "",
      typeModalconfirm: "",
      confirmMessage: "",
      currentItemModal: null,
      totalQuantity: 0,
      schoolLogo: null,
      loadingCrop: false,
      url: null,
      cropImg: null,

      // Cropv2
      imgLogoCrop: null,
      FaceStencil,
      Stencil,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },

      showEditTeacherModalVariable: false,

      showNotification: false,
      errorMessage: "",

      isErrorNotification: false,
    };
  },

  mounted() {
    const self = this;

    this.$root.$on("closeModal", () => {
      self.closeModal();
    });

    this.$root.$on("deleteSchool", (school) => {
      var result = self.list.filter((item) => {
        return item.id === school.id;
      });

      var index = self.list.indexOf(result[0]);

      if (index > -1) {
        self.list.splice(index, 1);
      }
    });

    // CRUD SCHOOLS
    this.$root.$on("addGrade", (grade) => {
      // var result = self.list.filter(item => {
      //   return item.id === school.id
      // })

      // var indexSchool = self.list.indexOf(result[0]);

      self.list.push(grade);
    });

    this.$root.$on("editGrade", (grade) => {
      // var result = self.list.filter(item => {
      //   return item.id === school.id
      // })

      var resultGrade = self.list.filter((item) => {
        return item.id === grade.id;
      });

      //var grades = result[0].grades;
      //var indexSchool = self.list.indexOf(result[0]);
      var index = self.list.indexOf(grade);
      self.list.splice(index, 1, grade);
    });

    this.$root.$on("deleteGrade", (grade) => {
      // var result = self.list.filter(item => {
      //   return item.id === school.id
      // })

      var resultGrade = self.list.filter((item) => {
        return item.id === grade.id;
      });

      //var grades = result[0].grades;
      var index = self.list.indexOf(resultGrade[0]);

      if (index > -1) {
        self.list.splice(index, 1);
      }
    });

    // AJAX CALLS
    // self.getGrades();
    // self.getTeacher();
    self.getProjectDetails();
    self.getGradesByType();
    self.getTeacher();
    // self.getKeyByValue();
    // self.getProducts();

    this.emptySchool.project_id = self.managerCredential.currentGrade;
    this.emptyGrade.project_id = self.managerCredential.currentGrade;
  },

  //props: ['address', 'subscription'],

  methods: {
    // viewGrade(item) {
    // 	alert("item");
    // },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      const newTab = window.open();
      newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
        "image/jpeg"
      )}"></img>`;
    },

    getTeacher() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .get(
          process.env.VUE_APP_ROOT_API + "/teachers/getClientTeachersForGrade",
          config
        )
        .then((response) => {
          console.log(response);
          self.listTeachers = response.data.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    editTeacher() {
      const self = this;

      Promise.all([
        this.$validator.validate("editTeacherName"),
        this.$validator.validate("editTeacherEmail"),
      ]).then(([isNameValid, isEmailValid]) => {
        // Verificar se ambas as validações são válidas
        if (!isNameValid) {
          return;
        }

        if (!isEmailValid) {
          return;
        }

        let config = {
          headers: {
            Authorization: "Bearer " + self.managerCredential.token,
          },
        };

        let payload = {
          name:
            self.editTeacherName !== undefined
              ? self.editTeacherName
              : self.teacherName,
          email:
            self.editTeacherEmail !== undefined
              ? self.editTeacherEmail
              : self.teacherEmail,
          username:
            self.editTeacherEmail !== undefined
              ? self.editTeacherEmail
              : self.teacherEmail,
        };

        axios
          .put(
            process.env.VUE_APP_ROOT_API + "/users/" + self.teacherId,
            payload,
            config
          )
          .then(function () {
            self.isErrorNotification = false;
            self.errorMessage = "Professor editado com sucesso";
            self.showNotificationError();
            self.closeEditTeacherModal();
            self.getTeacher();
          })
          .catch(function (error) {
            self.isErrorNotification = true;
            self.errorMessage = error.response.data.message;
            self.showNotificationError();
          });
      });
    },

    changeTeacher(item) {
      // Desvincula o professor atual se estiver vinculado
      if (item.linked) {
        this.teacherNameSelected = ""; // Remove o nome do professor
        this.teacherEmailSelected = ""; // Remove o email do professor
        this.teacherIdSelected = null; // Remove o ID do professor
        item.linked = false; // Marca como desvinculado
      } else {
        // Desvincula todos os professores vinculados na lista
        this.listTeachers.forEach((teacher) => {
          if (teacher.linked) {
            teacher.linked = false;
          }
        });

        // Vincula o novo professor
        this.teacherNameSelected = item.name || "Sem professor atrelado";
        this.teacherEmailSelected = item.email || "";
        this.teacherIdSelected = item.id; // Atualiza o ID do professor selecionado
        item.linked = true; // Marca o novo professor como vinculado
      }
    },

    saveGrade() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + self.managerCredential.token,
        },
      };

      let payload = {
        grade_type_id: self.selectedGrade,
        name: self.complemento,
        school_id: self.managerCredential.currentAdoption.school_id,
        project_id: self.managerCredential.currentAdoption.id,
        client_id: self.managerCredential.role_id,
        products: [self.selectedProductMain],
        user_id: self.teacherIdSelected,
      };

      axios
        .post(process.env.VUE_APP_ROOT_API + "/grades", payload, config)
        .then(function () {
          self.isErrorNotification = false;
          self.errorMessage = "Turma criada com sucesso";
          self.showNotificationError();
          self.goToGradesManager();
        })
        .catch(function (error) {
          self.isErrorNotification = true;
          self.errorMessage = error.response.data.message;
          self.showNotificationError();
        });
    },

    showNotificationError() {
      this.showNotification = true;
      setTimeout(() => {
        this.showNotification = false; // Esconde a notificação após 2 segundos
        self.errorMessage = "";
      }, 5000);
    },

    goToStudentsList() {
      const self = this;

      self.$router.push({ name: "students-list" });
    },

    goToGradesManager() {
      const self = this;

      self.$router.push({ name: "grades-manager" });
    },

    onChangeGradeType(event) {
      this.selectedGrade = event.target.value;
      //this.address = event.target.value
    },

    onChangeProduct(event) {
      this.selectedProductMain = event.target.value;
      //this.address = event.target.value
    },

    jsonCopy(src) {
      return JSON.parse(JSON.stringify(src));
    },

    showSchool(school, type) {
      this.schoolModalType = type;

      let copy = this.jsonCopy(school);
      this.schoolSelected = copy;
      this.$refs.largeModal.open();
    },

    showGrade(grade, type) {
      this.gradeModalType = type;
      this.gradeSelected = this.jsonCopy(grade);

      //this.schoolSelected = school
      this.$refs.gradeModal.open();
    },

    showEditTeacherModal(item) {
      const self = this;

      self.teacherName = item.name;
      self.teacherEmail = item.email;
      self.teacherId = item.id;

      this.showEditTeacherModalVariable = item.email;

      this.$refs.editTeacherModal.open();
    },
    closeEditTeacherModal() {
      this.$refs.editTeacherModal.close();
      this.$refs.teacherModal.close();
      this.teacherName = "";
      this.teacherEmail = "";
      this.errors.clear();
    },

    showTeacherModal() {
      this.$refs.teacherModal.open();
    },
    closeTeacherModal() {
      this.$refs.teacherModal.close();
      this.teacherName = "";
      this.teacherEmail = "";
      this.errors.clear();
    },

    submitTeacher() {
      const self = this;

      Promise.all([
        this.$validator.validate("teacherName"),
        this.$validator.validate("teacherEmail"),
      ]).then(([isNameValid, isEmailValid]) => {
        // Verificar se ambas as validações são válidas
        if (!isNameValid) {
          return;
        }

        if (!isEmailValid) {
          return;
        }

        // Configuração dos headers, incluindo o token de autorização
        let config = {
          headers: {
            Authorization: "Bearer " + self.managerCredential.token,
          },
        };

        // Dados do novo professor a serem enviados na requisição POST
        let payload = {
          teacher: {
            name: self.teacherName, // Nome do professor
            email: self.teacherEmail, // Email do professor
          },
        };

        axios
          .post(process.env.VUE_APP_ROOT_API + "/teachers/add", payload, config)
          .then(function (response) {
            self.isErrorNotification = false;
            self.errorMessage = "Professor adicionado com sucesso";
            self.showNotificationError();
            self.getTeacher(); // Chama o método getGradesByType em caso de sucesso
            self.closeTeacherModal(); // Fecha o modal e limpa os campos
            self.changeTeacher(response.data.data.user);
          })
          .catch(function (error) {
            self.isErrorNotification = true;
            self.errorMessage = error.response.data.message;
            self.showNotificationError();
          });
      });
    },

    openGrade(item) {
      const self = this;

      // Salvar um grade INTEIRA DENTRO DO VUEX
      self.$store.commit("SAVE_MANAGER_CURRENT_GRADE", item);
      self.$router.push({ name: "grades-edit" });
    },

    closeEditBooks() {
      const self = this;

      self.disableProductsEdit();
    },

    closeAndSaveBooksEdit() {
      const self = this;

      self.disableProductsEdit();
      self.getProjectDetails();
    },

    closeModal() {
      if (this.$refs.largeModal) {
        this.$refs.largeModal.close();
      }

      if (this.$refs.gradeModal) {
        this.$refs.gradeModal.close();
      }

      if (this.$refs.miniModal) {
        this.$refs.miniModal.close();
      }

      if (this.$refs.optionModalModal) {
        this.$refs.optionModalModal.close();
      }

      if (this.$refs.cropModal) {
        this.$refs.cropModal.close();
      }

      this.gradeSelected = null;
    },

    clickOnFile() {
      this.$refs.files.click();
    },

    submitFiles() {
      let formData = new FormData();

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("csv", file);
      }

      axios
        .post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.managerCredential.token,
          },
        })
        .then(function () {
          alert("Upload feito com sucesso");
        })
        .catch(function () {
          alert("Erro, upload falhou");
        });
    },

    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.submitFiles();
    },

    saveAddress() {
      const self = this;

      let config = {
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.percentCall = percentCompleted;
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line

          // this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
          this.addressEdit.nameRecipient =
            this.addressEdit.name + " " + this.addressEdit.lastname;
          this.subscriptionSend.address = this.addressEdit;

          if (this.isCreation) {
            axios
              .post(
                process.env.VUE_APP_ROOT_API + "/customer/address",
                this.subscriptionSend,
                config
              )
              .then(function (response) {
                if (response.data.success !== false) {
                  self.$router.push({ name: "adresses" });
                  self.percentCall = 0;
                } else {
                  self.errors.push(response.data.errors[0]);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            axios
              .put(
                process.env.VUE_APP_ROOT_API + "/customer/address",
                this.subscriptionSend,
                config
              )
              .then(function (response) {
                if (response.data.success !== false) {
                  self.$router.push({ name: "adresses" });
                  self.percentCall = 0;
                } else {
                  self.errors.push(response.data.errors[0]);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      });
    },

    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },

    scrollParent(slug) {
      this.$router.push({ name: slug });
    },

    enableProductsEdit() {
      this.editing = true;
      this.$refs.miniModalBookEdit.open();
    },

    goToStudentsList() {
      const self = this;

      self.$router.push({ name: "students-list" });
    },

    disableProductsEdit() {
      this.editing = false;
      this.getProducts();
      this.getProjectDetails();

      this.$refs.miniModalBookEdit.close();
    },

    getProjectDetails() {
      const self = this;

      self.productsExtra = [];

      // AJAX CALLS
      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/products/available/" +
            self.managerCredential.currentAdoption.id,
          config
        )
        .then((response) => {
          var list = [];

          response.data.data.products.forEach((element) => {
            var itemList = {
              key: element.id,
              value: element.name,
            };
            //if (list.indexOf(element.shippingAddressId) === -1) {
            list.push(itemList);
            //}
          });

          self.products = list;
        })
        .catch((e) => {
          this.errors.push(e);
        });

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/projects/get_project_details/" +
            self.managerCredential.currentAdoption.id,
          config
        )
        .then((response) => {
          self.projectInfos = response.data.data.details;
          // schoolLogo
          self.schoolLogo = self.projectInfos.school_logo;
          var listProducts = [];
          self.totalQuantity = 0;

          if (self.projectInfos.books.length) {
            self.projectInfos.books.forEach((element) => {
              var newProduct = { id: element.id, quantity: element.quantity };
              self.totalQuantity = self.totalQuantity + element.quantity;

              listProducts.push(newProduct);
            });
          }
          self.productsExtra = listProducts;
        })
        .catch((e) => {});
    },

    getGradesByType() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .get(process.env.VUE_APP_ROOT_API + "/grades_types", config)
        .then((response) => {
          console.log(response);
          var list = [];

          response.data.data.grade_types.forEach((element) => {
            var itemList = {
              key: element.id,
              value: element.name,
            };
            //if (list.indexOf(element.shippingAddressId) === -1) {
            list.push(itemList);
            //}
          });

          self.grades_types = list;
          self.getCurrentGradeInfo();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getCurrentGradeInfo() {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/grades/" +
            self.managerCredential.currentAdoption.currentGrade.id,
          config
        )
        .then((response) => {
          self.gradeInfo = response.data.data.grade;
          // self.getKeyByValue();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    openModalConfirmation(type, item) {
      const self = this;

      self.currentItemModal = item;

      self.$refs.optionModalModal.open();
      self.typeModalconfirm = type;

      if (type === "nf") {
        self.confirmMessage =
          "Ao confirmar será enviado a nota fiscal para o responsável da escola";
      } else if (type === "print") {
        self.confirmMessage =
          "Você deseja liberar os livros para impressão? Lembre-se de certificar se o pagamento já foi realizado antes de confirmar o envio para produção.";
      } else if (type === "checkout") {
        self.confirmMessage =
          "Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola";
      } else if (type === "adoption_delete") {
        self.confirmMessage =
          "Você tem certeza que deseja excluir esta adoção?";
      }
    },

    sendCheckout(item) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      self.errors.items = [];

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/projects/send_checkout/" +
            self.managerCredential.currentAdoption.id,
          config
        )
        .then((response) => {
          self.closeModal();
          self.customResponse = response.data.data.msg;
          self.$refs.miniModal.open();
        })
        .catch(function (error) {
          let fieldError = {
            field: "error-modal",
            msg: error.response.data.message,
            rule: "error", // optional
          };
          self.$validator.errors.add(fieldError);
        });
    },

    deleteAdoption(item) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      self.errors.items = [];

      axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/projects/delete/" +
            self.managerCredential.currentAdoption.id,
          [],
          config
        )
        .then((response) => {
          self.$router.push({ name: "adoptions-manager" });
          self.closeModal();
        })
        .catch(function (error) {
          console.log(error);
          let fieldError = {
            field: "error-modal",
            msg: error.response.data.message,
            rule: "error", // optional
          };
          self.errors.items.push(fieldError);
        });
    },

    sendNF(item) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      self.errors.items = [];

      axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/projects/create_invoice/" +
            self.managerCredential.currentAdoption.id,
          config
        )
        .then((response) => {
          //alert("NF");
          //self.list = response.data.data.projects
          self.closeModal();
          self.customResponse = response.data.data.msg;
          self.$refs.miniModal.open();
        })
        .catch(function (error) {
          let fieldError = {
            field: "error-modal",
            msg: error.response.data.message,
            rule: "error", // optional
          };
          self.errors.items.push(fieldError);
        });
    },

    onFileChange(e) {
      const self = this;

      var file = e.target.files[0];

      var img = new Image();
      var objectUrl = URL.createObjectURL(file);
      //var urlFinal =

      img.onload = function () {
        if (this.width > 600) {
          self.url = objectUrl;
          //URL.revokeObjectURL(objectUrl);
          self.$refs.cropModal.open();
        } else {
          alert("A imagem deve ter ao menos 600px de resolução");
        }
      };

      img.src = objectUrl;
    },

    showFileChooser() {
      this.$refs.files.click();
    },

    uploadImage(event) {
      const self = this;

      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();
        self.$refs.cropModal.open();

        reader.onload = (e) => {
          const logoImg = new Image();
          logoImg.src = e.target.result;
          logoImg.onload = () => {
            const canvas = self.$refs.resizeLogoCCanvas;
            const ctx = canvas.getContext("2d");

            const maxWidth = 500;
            const maxHeight = 500;
            let width = logoImg.width;
            let height = logoImg.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            const scaleFactor = 0.8;
            width *= scaleFactor;
            height *= scaleFactor;

            canvas.width = maxWidth;
            canvas.height = maxHeight;
            ctx.clearRect(0, 0, maxWidth, maxHeight);

            const x = (maxWidth - width) / 2;
            const y = (maxHeight - height) / 2;

            ctx.drawImage(logoImg, x, y, width, height);

            this.imgLogoCrop = canvas.toDataURL("image/png");
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },

    roundEdges(canvas) {
      const context = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2;

      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = width;
      tempCanvas.height = height;

      const tempContext = tempCanvas.getContext("2d");
      tempContext.drawImage(canvas, 0, 0, width, height);

      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.arc(centerX, centerY, radius, 0, Math.PI * 2);
      context.closePath();
      context.clip();

      context.drawImage(tempCanvas, 0, 0, width, height);

      return canvas;
    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      const newTab = window.open();
      newTab.document.body.innerHTML = `<img src="${result.canvas.toDataURL(
        "image/jpeg"
      )}"></img>`;
    },

    crop() {
      const self = this;
      const result = this.$refs.cropper.getResult();
      const roundedImage = self
        .roundEdges(result.canvas)
        .toDataURL("image/png");
      self.uploadLogo(roundedImage);
    },

    dataUrlToBlob(dataUrlLogo) {
      const arr = dataUrlLogo.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    async uploadLogo(image) {
      const self = this;
      const blob = this.dataUrlToBlob(image);
      const formData = new FormData();
      const projectId = self.managerCredential.currentAdoption.id;
      const url = process.env.VUE_APP_ROOT_API + "/projects/upload";
      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };

      formData.append("file", blob, "school_logo.png");
      formData.append("project_id", projectId);

      try {
        const response = await axios.post(url, formData, config);
        this.schoolLogo = response.data.data.file_url;
        this.closeModal();
      } catch (error) {
        console.error("Erro ao fazer upload:", error);
      }
    },

    removeLogo() {
      const self = this;
      const projectId = self.managerCredential.currentAdoption.id;
      const url =
        process.env.VUE_APP_ROOT_API + "/projects/deleteLogo/" + projectId;
      let config = {
        headers: {
          Authorization: "Bearer " + this.managerCredential.token,
        },
      };
      axios
        .delete(url, config)
        .then((response) => {
          this.schoolLogo = null;
        })
        .catch((error) => {
          console.error("Erro ao remover a logo:", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.hide {
  display: none;
}
@media (max-width: 580px) {
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

// .fiscal-notes ::v-deep .form-group:last-child {
// 	margin-bottom: 10px !important;
// }

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}
small {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-indent: 15px;
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  // padding-left: 15px;
}
h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}
.address-info {
  margin-bottom: 15px;
}
.editing {
  margin-top: 15px;
}
.radio-button-wrapper {
  padding: 14px 11px;
  margin-top: 10px;

  @media (max-width: 990px) {
    padding: 20px 19px;
    margin-top: 10px;
  }
}
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.add-new-card {
  border-radius: 10px;
  border: dashed 2px #2cd3d8;
  background-color: #ffffff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #4965bc;
  margin-bottom: 20px;
  padding: 15px 0px;
  cursor: pointer;

  .plus {
    font-family: "Nunito";
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    transform: rotate(-180deg);
    border-radius: 40px;
    border: solid 1px #4965bc;
    background-color: #4965bc;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 36px;
  }
}

// Default of page

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: white;

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #68596e;
    margin-top: 34px;
    margin-bottom: 34px;
    line-height: 1;
    white-space: nowrap !important;
    width: 90%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.my-data {
  /* Facebook bar */
  .facebook-tag {
    height: 65px;
    border-radius: 5px;
    background-color: #3a5998;
    color: white;
    padding: 14px 20px;
    margin-bottom: 20px;

    svg {
      font-size: 26px;
      margin-right: 0px;
      float: left;

      path {
        fill: #fff;
      }
    }

    .name {
      display: inline-block;
      margin-top: 1px;
      line-height: 37px;
    }

    .facebook-toggle {
      float: right;
      display: block;
      margin-top: 5px;
    }
  }
}

.change-password {
  border-radius: 10px;
  border: solid 2px #f0ebf5;
  background-color: #ffffff;
  padding: 24px 20px;
  position: relative;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: "Roboto";

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .floated-icon {
    float: left;
    width: 36px;
    height: 36px;
    font-size: 30px;

    svg {
      position: relative;
      top: -5px;
      left: -3px;

      path {
        fill: #afa2ba;
      }
    }
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }
}

.my-data-table {
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.fiscal-notes {
  border-radius: 10px;
  background-color: transparent;
  position: relative;
  border: none;
  box-shadow: none !important;
  margin-bottom: 0px !important;

  .table-list-simple {
    margin: 0px;
  }

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .body-spacing {
    // padding: 30px 30px 10px 30px;

    // @media (max-width: 990px) {
    // 	padding: 25px 15px 15px 15px;
    // }

    .btn-change-edit {
      border: none;
      color: #4965bc;
      background: transparent;
      font-size: 20px;
      padding: 0px 8px;
      &:disabled {
        color: #dfdae4;
      }

      svg {
        path {
          fill: #4965bc;
        }
      }
      &:disabled {
        svg {
          path {
            fill: #dfdae4;
          }
        }
      }
    }

    .btn-change-edit-disabled {
      border: none;
      color: #4965bc60;
      background: transparent;
      font-size: 20px;
      padding: 0px 8px;
    }

    .notification {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #df463e;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      font-size: 14px;
      animation: fade-in-out 2s ease-in-out;
      z-index: 1000;
    }

    .notification-success {
      background-color: #4caf50;
    }

    .notification-error {
      background-color: #df463e;
    }

    /* svg {
					path {
						fill: #4965bc60;
					}
				} */

    //  div:last-child{
    //   margin-bottom: 0px;
    // }
  }

  hr {
    border-top: solid 1px #f0ebf5;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  .company-code {
    text-transform: capitalize;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  // .panel:last-child{
  //   margin-bottom: 0px;
  // }
}

.address-boxes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  svg {
    path {
      fill: #afa2ba;
    }
  }

  .clube {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .body-spacing {
    padding: 30px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }
  }

  hr {
    border-top: solid 2px #f0ebf5;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }

  .address-row {
    padding: 16px 0px;
    position: relative;
    border-bottom: solid 1px #f0ebf5;
    b {
      font-size: 16px;
      color: #000000;
    }
    :last-child {
      border-bottom: none;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 10px;
  border: solid 2px #9378f0;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}

.created-data {
  font-size: 12px;
  display: block;
  color: #afa2ba;

  &.init {
    margin-bottom: 10px;
  }
  &.pay {
    cursor: pointer;
    color: #4965bc;
    // width: 100%;
    display: contents;

    &:hover {
      text-decoration: underline;
      color: #afa2ba !important;
    }
  }
}

.btn-edit {
  text-align: right;
}

.btn-group-custom {
  // place-self: flex-end;
  // display: inline-flex;
  // margin-bottom: 16px;
  align-items: center;
  display: flex;
}

.team {
  .item {
    width: 22% !important;
  }

  .teacher {
    width: 20% !important;
  }

  .books-created {
    width: 20% !important;
  }

  .status {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: #000000;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: 24px;
      border-radius: 50%;
    }

    &.blue {
      &:before {
        background-color: #1890ff;
      }
    }

    &.green {
      &:before {
        background-color: #52c41a;
      }
    }

    div {
      padding-left: 16px;
    }
  }

  .actions {
    text-align: right !important;
    margin-left: auto;
  }

  .link {
    // border-bottom: 1px solid #4965bc;
    color: #4965bc;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }

  .full-line {
    width: 100% !important;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
  }

  .lines {
    background-color: #ffffff;

    .item {
      padding: 24px;
      // width: 16.6%;
    }
  }
}

.books-list {
  .book-cover-title {
    width: 67% !important;
    padding-left: 0px !important;
  }

  .book-cover-image {
    width: 16.6% !important;
    padding: 0;

    img {
      max-width: 150px;
    }
  }

  .book-name {
    width: 50% !important;
    h4 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      /* color / neutral / darkest */

      color: #68596e;
    }
  }

  .book-quantity {
    width: 23% !important;
  }

  .material-title {
    width: 21%;
  }

  .action {
    width: 10% !important;
  }

  .link {
    // border-bottom: 1px solid #4965bc;
    color: #4965bc;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
  }

  .full-line {
    width: 100% !important;
  }

  .lines {
    background-color: #ffffff;

    .item {
      padding: 24px;
      // width: 16.6%;
    }
  }

  .action {
    text-align: right;
  }
}

.persons {
  .item {
    width: 20% !important;
  }

  .email {
    width: 20% !important;
  }

  .books-created {
    width: 20% !important;
  }

  .link {
    // border-bottom: 1px solid #4965bc;
    color: #4965bc;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
  }

  .full-line {
    width: 100% !important;
  }

  .lines {
    background-color: #ffffff;

    .item {
      padding: 24px;
      // width: 16.6%;
    }
  }
}

.users {
  .item {
    width: 16.6% !important;
  }

  .header {
    padding-left: 0px;
    padding-right: 0px;
  }

  .email {
    width: 30% !important;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }

  .phone {
    width: 20% !important;
  }

  .link {
    // border-bottom: 1px solid #4965bc;
    color: #4965bc;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
  }

  .full-line {
    width: 100% !important;
    text-align: center;
  }

  .action {
    text-align: right;
  }
  .lines {
    background-color: #ffffff;

    .item {
      padding: 24px;
      // width: 16.6%;
    }
  }
}

.pending {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* color / brand / yellow / darkest */

  color: #f98e00;
}

.pending-label {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* color / feedback / success */

  color: #f98e00;
}

.paid {
  /* text-xs/leading-4/font-medium */

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* color / feedback / success */

  color: #00cf72;
}

.exclude {
  color: #ff4641;
  border-color: #ff4641;
  margin-top: 10px;
  background-color: transparent;

  margin-top: 24px;

  &:hover {
    background-color: #ffedec;
  }
}

.header-button {
  justify-content: right;
}

.add-grade {
  margin-left: 8px;
}

.container-dhh {
  margin-bottom: 64px !important;
}

.container-dhh-menu {
  padding: 0px 15px !important;
}

.empty-phrase {
  .main {
    width: 83.4% !important;
  }

  .generate {
    text-align: right !important;

    justify-content: flex-end;
  }
}
.nome-da-marca {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #68596e;
}

.logo-position {
  position: relative;

  .school-logo {
    height: 64px;
    width: 64px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-left: 24px;
  }

  .close-button {
    //background-color: red; /* Escolha a cor desejada */
    color: red;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    font-size: 12px;
    top: -6px;
    right: -6px;
    border: 1px solid #ccc;
    border-radius: 50%;
  }
}

.btn-back {
  position: relative;
  top: 0;
  bottom: 0;
  height: 64px;
  margin: auto;
  width: 64px;
  display: block;
  height: 64px;
}

.header-back-button {
  display: flex;
  align-items: center;

  h1 {
    margin-left: 20px;
  }
}

.cta-crop {
  padding-top: 24px;
}
</style>
