<template>
  <div class="flex xs12 no-padding box-input-item">
    <div
      class="floating-label"
      :class="{ active: isEditing, 'has-error': errors.has(getName) }"
    >
      <div class="input-group">
        <div class="input-text-group">
          <input
            :disabled="!isEditing"
            :type="type"
            :name="getName"
            placeholder=" "
            :focus="isEditing"
            v-bind="$attrs"
            v-on="$listeners"
            :data-vv-as="label"
            :ref="getName"
            v-validate="validate"
            :maxlength="maxlength"
            class="floating-input"
          />
          <label
            :for="getName"
            :class="[{ active: isEditing, 'has-error': errors.has(getName) }]"
            >{{ label }}</label
          >

          <div class="editor">
            <button
              class="btn btn-micro-edit"
              type="button"
              v-on:click="showEditButtons()"
              v-if="!isEditing"
            >
              Editar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- {{errors}} -->
    <transition name="fade-up">
      <div v-if="errors.has(getName)" class="input-text-helper">
        <span v-if="errors.has(getName)" class="input-text-helper-error">
          {{ errors.first(getName) }}
        </span>
        <span v-else class="input-text-helper-default">
          {{ helper }}
        </span>
      </div>
    </transition>

    <div
      class="flex xs12 md7 offset--md5 no-padding edit-fields"
      v-if="isEditing"
    >
      <button
        type="button"
        class="btn btn-primary right"
        v-on:click="saveValueLabel()"
      >
        SALVAR EDIÇÃO
      </button>
      <button
        type="button"
        class="btn btn-primary right cancel"
        v-on:click="hideEditButtons()"
      >
        CANCELAR
      </button>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
// const axios = require('axios');

export default {
  name: "input-edit",
  props: {
    /**
     * Indica se o botão esta desabilitado ou não
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    helper: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    validate: {
      type: String,
      required: false,
      default: null,
    },
    maxlength: {
      type: Number,
      required: false,
      default: 120,
    },
  },
  data() {
    return {
      isEditing: false,
      inputVal: this.value,
      oldValue: this.value,
    };
  },
  mounted() {
    this.inputVal = this.$attrs.value;
  },
  computed: {
    getName() {
      if (this.$attrs && this.$attrs.name) return this.$attrs.name;
      return this.label
        ? this.label.replace(new RegExp(" ", "g"), "").toLowerCase()
        : "";
    },
    showHelper() {
      return this.hasError || (this.helper && this.helper.length > 0);
    },
    hasError() {
      return this.error && this.error.length > 0;
    },
  },
  methods: {
    saveValueLabel() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.$root.$emit("myDataUpdate");
          this.isEditing = false;
        }
      });
    },

    showEditButtons() {
      this.isEditing = true;
      this.oldValue = this.$attrs.value;

      this.$nextTick(function () {
        var ref = this.getName;
        this.$refs[ref].focus();
      });
    },

    hideEditButtons() {
      this.isEditing = false;
      this.$attrs.value = this.oldValue;
      // this.$validator.validateAll();
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss">
.box-input-item {
  .input-text-group {
    width: 100%;
  }
  .input-text-helper {
    float: left;
    position: relative;
    top: -8px;
  }
  /****  floating-Lable style start ****/
  .floating-label {
    position: relative;
    margin-bottom: 10px;
  }

  label {
    color: #b0a1bb;
    font-size: 16px;
    font-weight: normal !important;
    position: absolute;
    pointer-events: none;

    left: 22px;
    top: 21px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;

    @media (max-width: 990px) {
      left: 16px;
    }
  }

  .floating-input,
  .floating-select {
    padding: 4px 4px;
    display: block;
    width: 100%;
    background-color: transparent;
    height: 65px;
    border-radius: 5px;
    border: solid 1px #dfdae4;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-left: 20px;

    @media (max-width: 480px) {
      padding-right: 25%;
      font-size: 16px;
      height: 60px;
      padding-left: 15px;
    }
  }

  .floating-input:focus,
  .floating-select:focus {
    outline: none;
    //  border-bottom:2px solid #5264AE;
    border: solid 2px #4965bc;
    background-color: #ffffff;
  }

  .floating-input:not(:placeholder-shown) ~ label {
    top: 12px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4965bc;
  }

  .floating-input:not(:placeholder-shown) {
    padding-top: 18px;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value=""]):valid ~ label {
    top: -18px;
    font-size: 14px;
    color: #5264ae;
  }

  /* active state */
  .floating-input:focus ~ .bar:before,
  .floating-input:focus ~ .bar:after,
  .floating-select:focus ~ .bar:before,
  .floating-select:focus ~ .bar:after {
    width: 50%;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .floating-textarea {
    min-height: 30px;
    max-height: 260px;
    overflow: hidden;
    overflow-x: hidden;
  }

  /* highlighter */
  .highlight {
    position: absolute;
    height: 50%;
    width: 100%;
    top: 15%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  .floating-input:focus ~ .highlight,
  .floating-select:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  // Errors
  .input-text-helper-error {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    color: #ff4547;
    font-size: 14px;
  }

  .edit-fields {
    margin-bottom: 30px;

    @media (max-width: 480px) {
      text-align: center;

      .btn {
        margin-left: 10px;
      }
    }
  }

  .editor {
    right: 10px;
    top: 44%;
  }
}
</style>
