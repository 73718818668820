const state = {
	sidebar: {
		opened: false,
		withoutAnimation: false,
	},
	config: {
		googleMaps: {
			apiKey: "AIzaSyBNAqPrTQoz9P4NBlDDyfxrnKiafkaL8iQ",
		},
		windowMatchSizeLg: "(min-width: 992px)",
		palette: {
			primary: "#4ae387",
			danger: "#e34a4a",
			info: "#4ab2e3",
			success: "#db76df",
			warning: "#f7cc36",
			white: "#fff",
			black: "#000",
			fontColor: "#000",
			transparent: "transparent",
			lighterGray: "#ddd",
		},
	},
	isLoading: true,

	managerCredential: {
		currentAdoption: null,
	},
	userCredential: {},

	consultantCredential: {},
	principalCredential: {},
	teacherCredential: {},
	studentCredential: {},
	parentCredential: {},
};

const mutations = {
	setLoading(state, isLoading) {
		state.isLoading = isLoading;
	},
	// saveUserCredentials(token, project_id, role_id){
	//   state.sidebar.withoutAnimation = value
	// },

	// MANAGER
	CLEAR_SESSIONS(state, value) {
		state.managerCredential = null;
		state.userCredential = null;
		state.consultantCredential = null;
		state.principalCredential = null;
		state.teacherCredential = null;
		state.studentCredential = null;
		state.parentCredential = null;
	},

	// MANAGER
	SAVE_MANAGER_CREDENTIALS(state, value) {
		state.managerCredential = value;
	},

	SAVE_MANAGER_CURRENT_ADOPTION(state, value) {
		//state.managerCredential.currentAdoption = value
		Vue.set(state.managerCredential, "currentAdoption", value);
	},

	CLEAR_MANAGER_CURRENT_ADOPTION(state) {
		//state.managerCredential.currentAdoption = null
		Vue.set(state.managerCredential, "currentAdoption", []);
	},

	SAVE_MANAGER_CURRENT_ADOPTION_STEP(state, value) {
		state.managerCredential.currentAdoption.step = value;
	},

	SAVE_MANAGER_CURRENT_GRADE(state, value) {
		state.managerCredential.currentAdoption.currentGrade = value;
	},

	// CONSULTANT
	SAVE_CONSULTANT_CREDENTIALS(state, value) {
		state.consultantCredential = value;
	},

	SAVE_CONSULTANT_CURRENT_ADOPTION(state, value) {
		Vue.set(state.consultantCredential, "currentAdoption", value);
		//state.consultantCredential.currentAdoption = value
	},

	CLEAR_CONSULTANT_CURRENT_ADOPTION(state) {
		//state.consultantCredential.currentAdoption = null
		Vue.set(state.consultantCredential, "currentAdoption", []);
	},

	SAVE_CONSULTANT_CURRENT_ADOPTION_STEP(state, value) {
		state.consultantCredential.currentAdoption.step = value;
	},

	SAVE_CONSULTANT_CURRENT_GRADE(state, value) {
		state.consultantCredential.currentGrade = value;
	},

	// 'SAVE_PRINCIPAL_CURRENT_GRADE' (state, value) {
	//   state.principalCredential.currentGrade = value
	// },

	//
	SAVE_USER_CREDENTIALS(state, value) {
		state.userCredential = value;
	},

	UPDATE_BOOK_LIMIT(state, value) {
		state.managerCredential.settings.book_limit = value;
	},

	// PRINCIPAL
	SAVE_PRINCIPAL_CREDENTIALS(state, value) {
		state.principalCredential = value;
	},

	SAVE_PRINCIPAL_CURRENT_ADOPTION(state, value) {
		state.principalCredential.currentAdoption = value;
	},

	// Teacher
	SAVE_TEACHER_CREDENTIALS(state, value) {
		state.teacherCredential = value;
	},

	SAVE_TEACHER_CURRENT_GRADE(state, value) {
		state.teacherCredential.currentGrade = value;
	},

	// Student
	SAVE_STUDENT_CREDENTIALS(state, value) {
		state.studentCredential = value;
	},

	SAVE_PREVIEW_BOOK(state, value) {
		state.studentCredential.preview = value;
	},

	SAVE_STUDENT_CUSTOM_CHARACTER(state, value) {
		state.studentCredential.custom_character = value;
	},

	UPDATE_STUDENT_UUID(state, value) {
		state.studentCredential.uuid = value;

		if (state.studentCredential.custom_character) {
			state.studentCredential.custom_character.uuid = value;
		}
	},

	UPDATE_STUDENT_UUID_CUSTOMIZATION(state, value) {
		state.studentCredential.main_product.customization = value;
	},

	UPDATE_STUDENT_NAME(state, value) {
		state.studentCredential.custom_character.user_data.properties.nome.value =
			value;
	},

	UPDATE_STUDENT_CREDENTIAL_NAME(state, value) {
		state.studentCredential.user.name = value;
	},

	UPDATE_STUDENT_CREDENTIAL_CUSTOM_NAME(state, value) {
		state.studentCredential.user.custom_name = value;
	},

	UPDATE_MAIN_PRODUCT(state, value) {
		state.studentCredential.main_product = value;
	},

	UPDATE_MAIN_PRODUCT_CUSTOM_NUMERO(state, value) {
		state.studentCredential.main_product.custom_numero = value;
	},

	UPDATE_MAIN_PRODUCT_CUSTOM_MES(state, value) {
		state.studentCredential.main_product.custom_mes = value;
	},

	UPDATE_STUDENT_IS_UPDTADING(state, value) {
		state.studentCredential.isUpdate = value;
	},

	UPDATE_STUDENT_OLD_UUID(state, value) {
		state.studentCredential.old_uuid = value;
	},

	UPDATE_STUDENT_GENDER(state, value) {
		state.studentCredential.user.gender = value;
	},

	UPDATE_STUDENT_PET_NAME(state, value) {
		state.studentCredential.custom_character.user_data.properties[
			"nome-pet"
		].value = value;
	},

	UPDATE_STUDENT_LAYOUT_ID(state, value) {
		state.studentCredential.custom_character.layout_id = value;
	},

	UPDATE_STUDENT_PET(state, value) {
		//state.studentCredential.uuid = value
		state.studentCredential.custom_character.categories[value.index].id =
			value.id;
		state.studentCredential.custom_character.categories[value.index].parent_id =
			value.parent_id;
	},

	UPDATE_STUDENT_DEDICATION(state, value) {
		//state.studentCredential.uuid = value
		state.studentCredential.dedication = value;
	},

	SAVE_STUDENT_SUBCRIPTION(state, value) {
		state.studentCredential.subscription = value;
	},

	// PARENT
	SAVE_PARENT_CREDENTIALS(state, value) {
		state.parentCredential = value;
	},

	SAVE_PARENT_CURRENT_STUDENT(state, value) {
		state.parentCredential.selectedStudent = value;
	},

	SAVE_PARENT_CURRENT_STUDENT_STATUS(state, value) {
		state.parentCredential.students[value].status = "waiting";
		//state.parentCredential.selectedStudent = 'waiting'
	},

	TOGGLE_WITHOUT_ANIMATION(state, value) {
		state.sidebar.withoutAnimation = value;
	},
};

const actions = {
	isToggleWithoutAnimation({ commit }, value) {
		commit("TOGGLE_WITHOUT_ANIMATION", value);
	},

	saveManagerCredential({ commit }, value) {
		commit("SAVE_MANAGER_CREDENTIALS", value);
	},

	saveCleanManagerCredential({ commit }, value) {
		commit("SAVE_MANAGER_CREDENTIALS", value);
	},

	updateBookLimit({ commit }, value) {
		commit("UPDATE_BOOK_LIMIT", value);
	},

	saveUserCredential({ commit }, value) {
		commit("SAVE_USER_CREDENTIALS", value);
	},

	// Director
	savePrincipalCredential({ commit }, value) {
		commit("SAVE_PRINCIPAL_CREDENTIALS", value);
	},

	// Teacher
	saveTeacherCredential({ commit }, value) {
		commit("SAVE_TEACHER_CREDENTIALS", value);
	},

	saveTeacherCurrentGradeCredential({ commit }, value) {
		commit("SAVE_TEACHER_CURRENT_GRADE", value);
	},

	// Parents
	saveParentCredential({ commit }, value) {
		commit("SAVE_PARENT_CREDENTIALS", value);
	},

	saveParentCurrentStudent({ commit }, value) {
		commit("SAVE_PARENT_CURRENT_STUDENT", value);
	},

	saveParentCurrentStudentStatus({ commit }, value) {
		commit("SAVE_PARENT_CURRENT_STUDENT_STATUS", value);
	},

	// Student
	saveStudentCredential({ commit }, value) {
		commit("SAVE_STUDENT_CREDENTIALS", value);
	},

	saveStudentPreview({ commit }, value) {
		commit("SAVE_PREVIEW_BOOK", value);
	},

	saveStudentCustomCharacter({ commit }, value) {
		commit("SAVE_STUDENT_CUSTOM_CHARACTER", value);
	},

	saveStudentSubscription({ commit }, value) {
		commit("SAVE_STUDENT_SUBCRIPTION", value);
	},

	updateStudentUuid({ commit }, value) {
		commit("UPDATE_STUDENT_UUID", value);
	},

	updateCustomizationStudent({ commit }, value) {
		commit("UPDATE_STUDENT_UUID_CUSTOMIZATION", value);
	},

	clearSessions({ commit }, value) {
		commit("CLEAR_SESSIONS", value);
	},

	clearManagerCurrentAdoption({ commit }) {
		commit("CLEAR_MANAGER_CURRENT_ADOPTION");
	},

	clearConsultantCurrentAdoption({ commit }) {
		commit("CLEAR_CONSULTANT_CURRENT_ADOPTION");
	},
};

export default {
	state,
	mutations,
	actions,
};
