import Vue from "vue";
import Router from "vue-router";

import AuthLayout from "../components/auth/AuthLayout";
import AppLayout from "../components/admin/AppLayout";
import AppAngel from "../components/admin/AppAngel";
import AppConsultant from "../components/admin/AppConsultant";
import AppClean from "../components/admin/AppClean";
import AppPrincipal from "../components/admin/AppPrincipal";
import AppMaster from "../components/admin/AppMaster";

import lazyLoading from "./lazyLoading";

Vue.use(Router);

const demoRoutes = [];

if (process.env.NODE_ENV === "development") {
	// const VueBook = require('vue-book')
	// demoRoutes.push(
	//   VueBook.createRoute({
	//     requireContext: require.context('./..', true, /.demo.vue$/),
	//     path: '/demo',
	//   }),
	//   VueBook.createRoute({
	//     requireContext: require.context('./../components', true, /.vue$/),
	//     path: '/presentation',
	//   }),
	// )
	// Vue.use(VueBook.VueBookComponents)
}

const EmptyParentComponent = {
	template: "<router-view></router-view>",
};

export default new Router({
	routes: [
		...demoRoutes,

		{
			path: "*",
			redirect: { name: "main" },
		},
		{
			path: "/",
			redirect: { name: "main" },
		},

		// Gestor
		{
			name: "Manager",
			path: "/gestor",
			component: AppClean,
			children: [
				{
					path: "/gestor",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-manager" },
						},
						{
							name: "login-manager",
							path: "login",
							component: lazyLoading("auth/login/Login"),
						},
						{
							name: "recover",
							path: "esqueceu-a-senha",
							component: lazyLoading("auth/recover/Recover"),
						},
					],
				},

				{
					path: "/gestor",
					component: AppMaster,
					// meta: { requiresAuth: true },
					children: [
						{
							name: "dashboard-manager",
							path: "dashboard",
							component: lazyLoading("master/DashboardManager"),
							default: true,
						},

						{
							name: "consultant-manager",
							path: "consultores",
							component: lazyLoading("master/Consultants"),
							default: true,
						},

						{
							name: "adoptions-manager",
							path: "adocoes",
							component: lazyLoading("master/Adoptions"),
							default: true,
						},

						{
							name: "adoptions-pending",
							path: "adocoes-pendentes",
							component: lazyLoading("master/AdoptionsPending"),
							default: true,
						},

						{
							name: "grades-manager",
							path: "grade-manager",
							component: lazyLoading("master/GradesManager"),
							default: true,
						},

						{
							name: "grade-edit",
							path: "grade",
							component: lazyLoading("master/GradesEdit"),
							default: true,
						},

						{
							name: "students-list",
							path: "grade/students",
							component: lazyLoading("master/StudentsList"),
							default: true,
						},

						{
							name: "grade-add",
							path: "grade/new-grade",
							component: lazyLoading("master/GradesAdd"),
							default: true,
						},

						{
							name: "manager-books",
							path: "books-manager",
							component: lazyLoading("master/Books"),
							default: true,
							props: true,
						},
						{
							name: "manager-book",
							path: "book-manager",
							component: lazyLoading("master/Book"),
							default: true,
							props: true,
						},
					],
				},
			],
		},

		// Representante
		{
			name: "Consultant",
			path: "/consultor",
			component: AppClean,
			children: [
				{
					path: "/consultor",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-consultant" },
						},
						{
							name: "login-consultant",
							path: "login",
							component: lazyLoading("auth/login/LoginConsultant"),
						},
						{
							name: "recover",
							path: "esqueceu-a-senha",
							component: lazyLoading("auth/recover/Recover"),
						},
					],
				},

				{
					path: "/consultor",
					component: AppConsultant,
					// meta: { requiresAuth: true },
					children: [
						{
							name: "dashboard-consultant",
							path: "dashboard",
							component: lazyLoading("consultant/DashboardConsultant"),
							default: true,
						},

						{
							name: "adoption-consultant",
							path: "minhas-adocoes",
							component: lazyLoading("consultant/AdoptionsConsultant"),
							default: true,
						},

						{
							name: "adoptions-consultant-pending",
							path: "adocoes-pendentes",
							component: lazyLoading("consultant/AdoptionsPendingConsultant"),
							default: true,
						},

						{
							name: "grades-consultant",
							path: "minhas-grades",
							component: lazyLoading("consultant/GradesConsultant"),
							default: true,
						},
					],
				},
			],
		},

		// Professor
		{
			name: "Principal",
			path: "/diretor",
			component: AppClean,
			children: [
				{
					path: "/diretor",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-diretor" },
						},
						{
							name: "login-diretor",
							path: "login",
							component: lazyLoading("auth/login/LoginPrincipal"),
							default: true,
							props: true,
						},
					],
				},

				{
					path: "/diretor",
					component: AppPrincipal,
					children: [
						{
							name: "schedule",
							path: "cronograma",
							component: lazyLoading("principal/Schedule"),
							default: true,
							props: true,
						},

						{
							name: "grades-director-manage",
							path: "turmas-director",
							component: lazyLoading("principal/Grades"),
							default: true,
							props: true,
						},

						{
							name: "director-books",
							path: "books-director",
							component: lazyLoading("principal/Books"),
							default: true,
							props: true,
						},

						{
							name: "director-book",
							path: "book-director",
							component: lazyLoading("principal/Book"),
							default: true,
							props: true,
						},

						{
							name: "director-payment",
							path: "payment-director",
							component: lazyLoading("principal/Payment"),
							default: true,
							props: true,
						},

						{
							name: "director-my-class",
							path: "my-class-director",
							component: lazyLoading("principal/MyClass"),
							default: true,
							props: true,
						},
					],
				},
			],
		},

		// Professor
		{
			name: "Teacher",
			path: "/professor",
			component: AppClean,
			children: [
				{
					path: "/professor",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-professor" },
						},
						{
							name: "login-professor",
							path: "login",
							component: lazyLoading("auth/login/LoginTeacher"),
							default: true,
							props: true,
						},
					],
				},

				{
					path: "/professor",
					component: AppClean,
					children: [
						{
							name: "grades-director",
							path: "turmas-director",
							component: lazyLoading("teacher/GradesDirectorFinal"),
							default: true,
							props: true,
						},

						{
							name: "grades",
							path: "turmas",
							component: lazyLoading("teacher/Grades"),
							default: true,
							props: true,
						},

						{
							name: "grade",
							path: "turma",
							component: lazyLoading("teacher/Grade"),
							default: true,
							props: true,
						},

						{
							name: "add-students",
							path: "adicionar-aluno",
							component: lazyLoading("teacher/AddStudents"),
							default: true,
							props: true,
						},

						{
							name: "schools-project-teacher",
							path: "escolas",
							component: lazyLoading("master/SchoolsProject"),
							default: true,
						},

						{
							name: "infobook",
							path: "livro",
							component: lazyLoading("teacher/InfoBook"),
							default: true,
						},

						{
							name: "infobookdescription",
							path: "livro-descricao",
							component: lazyLoading("teacher/InfoBookDescription"),
							default: true,
							props: true,
						},

						{
							name: "extra",
							path: "extra",
							component: lazyLoading("teacher/Extra"),
							default: true,
						},
					],
				},
			],
		},

		// Estudante
		{
			name: "Student",
			path: "/aluno",
			component: AppClean,
			children: [
				{
					name: "LoginStudent",
					path: "/aluno",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-child" },
						},
						{
							name: "login-child",
							path: "login",
							component: lazyLoading("auth/login/LoginStudent"),
							default: true,
							props: true,
						},
					],
				},

				{
					name: "PagesStudent",
					path: "/aluno",
					component: AppClean,
					children: [
						{
							name: "student-step-1",
							path: "passo-1",
							component: lazyLoading("student/InfoStudent"),
							default: true,
							props: true,
						},

						{
							name: "student-step-2",
							path: "passo-2",
							component: lazyLoading("kits/AvatarTeacher"),
							default: true,
							props: true,
						},

						{
							name: "preview",
							path: "preview",
							component: lazyLoading("student/PreviewStudent"),
							default: true,
							props: true,
						},
					],
				},
			],
		},

		// Pais
		{
			name: "Parent",
			path: "/pais",
			component: AppClean,
			children: [
				{
					name: "LoginStudent",
					path: "/pais",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "login-parents" },
						},
						{
							name: "login-parents",
							path: "login",
							component: lazyLoading("auth/login/LoginParents"),
							default: true,
							props: true,
						},
						{
							name: "login-parents-signin",
							path: "login-signin",
							component: lazyLoading("auth/login/LoginParentsSignin"),
							default: true,
							props: true,
						},
						{
							name: "login-parents-step-1",
							path: "login-step-1",
							component: lazyLoading("auth/login/LoginParentsStep1"),
							default: true,
							props: true,
						},
						{
							name: "login-parents-step-2",
							path: "login-step-2",
							component: lazyLoading("auth/login/LoginParentsStep2"),
							default: true,
							props: true,
						},
					],
				},

				{
					name: "PagesStudent",
					path: "/pais",
					component: AppClean,
					children: [
						{
							name: "parents-home",
							path: "home-parents",
							component: lazyLoading("parents/Home"),
							default: true,
							props: true,
						},

						// {
						//   name: 'parents-preview',
						//   path: 'pais-preview',
						//   component: lazyLoading('kits/Preview'),
						//   default: true,
						//   props: true
						// },

						{
							name: "parents-checkout",
							path: "pais-checkout",
							component: lazyLoading("kits/Checkout"),
							default: true,
							props: true,
						},

						{
							name: "parents-preview",
							path: "pais-preview",
							component: lazyLoading("parents/Preview"),
							default: true,
						},
					],
				},
			],
		},
		
		// Main
		{
			name: "main-template",
			path: "/",
			component: AppClean,
			children: [
				{
					name: "MainTemplate",
					path: "/",
					component: AuthLayout,
					children: [
						{
							path: "",
							redirect: { name: "home" },
						},
						{
							name: "main",
							path: "home",
							component: lazyLoading("auth/login/Home"),
							default: true,
							props: true,
						},
						{
							name: "main-ftd",
							path: "ftd",
							component: lazyLoading("auth/login/HomeFtd"),
							default: true,
							props: true,
						},
						{
							name: "main-pcj",
							path: "pcj",
							component: lazyLoading("auth/login/HomePcj"),
							default: true,
							props: true,
						},
					],
				},
			],
		},
	],
});
